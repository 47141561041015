<template>
  <h2 class="text-center mt-0 font-normal">Create new password</h2>
  <form class="block text-center" novalidate @submit.prevent="onSubmit">
    <Password
      v-model="form.password"
      :strong-regex="passwordRegexString"
      toggleMask
      stron
      class="mb-5">
      <template #header>
        <h5 class="m-0 mb-2">Pick a password</h5>
      </template>
      <template #footer="sp">
        {{ sp.level }}
        <Divider />
        <p class="mt-2">Suggestions</p>
        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
          <li>At least one uppercase</li>
          <li>At least one numeric</li>
          <li>At least special character</li>
          <li>Minimum 8 characters</li>
        </ul>
      </template>
    </Password>
    <div class="flex align-items-center justify-content-center">
      <Btn
        type="submit"
        class="p-button-lg font-bold"
        label="Reset password"
        :loading="isLoading" />
    </div>
  </form>
</template>
<script setup>
import Password from "primevue/password";
import Btn from "primevue/button";
import Divider from "primevue/divider";

import { computed, reactive, ref } from "vue";

import { useToast } from "primevue/usetoast";
import { PASSWORD_REGEX } from "@/utils/validators";
import { useRoute, useRouter } from "vue-router";
import { sendNewPasswordRequest } from "@/api/authService";

const $route = useRoute();
const $router = useRouter();
const $toast = useToast();

let passwordRegexString = computed(() => {
  return `${PASSWORD_REGEX.toString()}`.replaceAll("\\\\", "\\").slice(1, -2);
});

const form = reactive({
  password: "",
});

let isLoading = ref(false);

const onSubmit = () => {
  if (isLoading.value) {
    return;
  }
  if (!PASSWORD_REGEX.test(form.password)) {
    showToast({
      severity: "info",
      summary: "Password",
      detail: "Your password should be strong as you!",
    });
    return;
  }
  sendPassword();
};

const sendPassword = async () => {
  try {
    isLoading.value = true;
    const body = {
      password: form.password,
      token: $route.params.token,
      email: $route.params.email,
      passwordConfirmation: form.password,
    };
    await sendNewPasswordRequest(body);
    showToast({ severity: "success", summary: "Success", detail: "Password was changed" });
    await $router.push({ name: "Login", query: { email: $route.params.email } });
  } catch (e) {
    if (e.response && e.response.status === 422) {
      let { password } = e.response.data.errors;
      password.forEach((detail) => {
        showToast({ severity: "error", summary: "Password", detail });
      });
    }
  } finally {
    isLoading.value = false;
  }
};

const showToast = ({ severity = "error", summary = "", detail = "" }) => {
  $toast.add({
    severity,
    summary,
    detail,
    life: 4000,
  });
};
</script>
<style scoped></style>
