import { required, maxLength, helpers } from "@vuelidate/validators";

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@_$!%*#?&])[A-Za-z\d@_$!%*#?&]{8,}$/;

const passwordPattern = helpers.withParams(
  { type: "passwordPattern" },
  (value) => !helpers.req(value) || PASSWORD_REGEX.test(value)
);

export const passwordRules = {
  required,
  maxLength: maxLength(18),
  passwordPattern: helpers.withMessage(
    "Password should have min 8 symbols, 1 letter, 1 number, 1 special character",
    passwordPattern
  ),
};
